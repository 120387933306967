<template>
  <van-form validate-trigger="onChange" @submit="submit">
    <div v-for="(item, index) in fieldData" :key="index">
      <van-cell :title="item.title" border is-link :clickable="false"
        v-if="item.type === 'text'" center size="small">
        <template #right-icon >
          <van-field
            :name="item.name"
            :disabled="item.disabled"
            
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :rules="item.rule"
            v-on:blur="emitChange()"
            :border="false"
            style="width:60%"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell v-if="item.type === 'textarea'" :title="item.title">
        <template #label>
          <van-field
            :name="item.name"
            :disabled="item.disabled"
            :show-word-limit="item.show_word_limit"
            :maxlength="item.max_length"
            type="textarea"
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :rows="item.rows"
            :rules="item.rule"
            v-on:blur="emitChange()"
          />
        </template>
      </van-cell>
      <van-cell is-link border :title="item.title" 
        v-if="item.type === 'area'" center>
        <template #right-icon>
          <van-field
            type="text"
            placeholder="请选择省市区"
            v-model="modal[item.name]"
            :border="false"
            readonly
            @click="showLocationSelection = true"
            :rules="[{ required: true, message: '' }]"
            style="width:60%"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell is-link center border :title="item.title"
        v-if="item.type === 'password' && !item.inline">
        <template #right-icon>
          <van-field
            :name="item.name"
            :placeholder="`请输入${item.title}`"
            v-model="modal[item.name]"
            :disabled="item.disabled"
            :rules="item.rule"
            type="password"
            :border="false"
            v-on:blur="emitChange()"
            style="width:60%"
          />
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-field
        :name="item.name"
        :label="item.title"
        :disabled="item.disabled"
        v-if="item.type === 'password' && item.inline"
        :placeholder="`请输入${item.title}`"
        v-model="inlineModal[item.name].value"
        :rules="item.rule"
        type="password"
        v-on:blur="emitChange()"
      />
      <van-cell border center :value="item.title" 
        v-if="item.type === 'file' && (item.compressed == null || item.compressed)" is-link>
        <template #right-icon>
          <van-uploader
            accept="image/*"
            :max-count="item.max_count"
            :after-read="(option) => fnUploadRequest(option, item.name)"
          >
            <van-image
              width="40" height="40" fit="cover" :src="modal[item.name]" round />
          </van-uploader>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell border center :title="item.title" 
        v-if="item.type === 'file' && (item.compressed != null && !item.compressed)">
        <template #label>
          <van-uploader
            accept="image/*"
            :max-count="item.max_count"
            :after-read="(option) => fnUploadRequest(option, item.name)"
          >
            <van-image v-if="modal[item.name] != ''"
            width="100" height="100" fit="cover" :src="modal[item.name]" />
          </van-uploader>
        </template>
      </van-cell>
      <van-cell border center is-link :title="item.title" 
        v-if="item.type === 'select'">
        <template #right-icon>
          <van-field
            readonly
            clickable
            :border="false"
            :name="item.name"
            :value="modal[item.name]"
            :placeholder="`请选择${item.title}`"
            :rules="item.rule"
            @click="
              currentSelectionData = item;
              selectionShow = true;
            "
            v-on:blur="emitChange()"
            style="width:60%"
          >
          </van-field>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      <van-cell :title="item.title" is-link :clickable="false" 
        v-if="item.type === 'date_select'" center size="small">
        <template #right-icon>
          <van-field
            type="text"
            :placeholder="`请选择${item.title}`"
            v-model="modal[item.name]"
            :border="false"
            :rules="item.rule"
            style="width:60%"
            @click="
              showDateSelect = true;
              currentDateSelectField = item.name;
            "
          >
          </van-field>
          <van-icon name="arrow" />
        </template>
      </van-cell>
      
      <!-- <van-action-sheet v-model="showLocationSelection" v-if="item.type === 'area'">
        <van-cascader
          v-model="modal[item.name]"
          title="请选择所在地区"
          :options="regionData"
          @close="showLocationSelection = false"
          @finish="onAreaConfirm"
        />
      </van-action-sheet> -->
      <!-- <van-field
        v-if="item.type === 'address'"
        readonly
        clickable
        name="area"
        :value="modal[item.name]"
        label="区县选择"
        placeholder="点击选择省市区"
        @click="showArea = true"
      />
      <van-popup v-model="showArea" position="bottom">
        <van-area
          :area-list="areaList"
          @confirm="onConfirm"
          @cancel="showArea = false"
        />
      </van-popup> -->
    </div>
    <van-popup v-model="selectionShow" position="bottom">
      <van-picker
        show-toolbar
        :columns="currentSelectionData.values"
        @confirm="onSelectionConfirm"
        @cancel="selectionShow = false"
        v-on:blur="emitChange()"
      />
    </van-popup>
    <van-action-sheet v-model="showDateSelect" :overlay="true">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
          v-on:blur="emitChange()"
          @confirm="onDateConfirm"
        />
      </van-action-sheet>
    <div class="action_bg">
      <van-button
        block
        type="info"
        native-type="submit"
        style="z-index: 999"
        class="action_btn"
        color="#395EE8"
        v-on:change="emitChange()"
        round
        >{{submitBtnText}}</van-button
      >
    </div>
  </van-form>
</template>

<script>
import oss from "@/utils/oss";
import { Toast } from "vant";
import {mapGetters} from 'vuex'
export default {
  name: "fieldForm",
  props: {
    modal: {
      type: Object,
      default: () => {
        return {};
      },
    },
    fieldData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    inlineModal: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submit: {
      type: Function,
    },
    submitBtnText: {
      type: String,
      default: "提交"
    }
  },
  data() {
    return {
      showArea: false,
      currentDate: new Date(),
      selectionShow: false,
      currentSelectionData: {},
      showDateSelect: false,
      currentDateSelectField: "",
      minDate: new Date(1900, 0, 1),
      maxDate: new Date(),
      showLocationSelection: false
    };
  },
  created() {
    // this.$store.dispatch('wode/getRegionData')
  },
  computed: {
    // ...mapGetters('wode', {
    //   regionData: 'getRegionData'
    // }),
    fileList() {
      let _self = this;
      let result = {};
      _self.fieldData.forEach(function (ele) {
        if (ele.type === "file") {
          const files = _self.modal[ele.name];
          result[ele.name] = [];
          if (files != "") {
            result[ele.name].push({ url: files, isImage: true });
          }
        }
      });
      return result;
    },
    locationStrs() {
      let result = {}
      this.fieldData.map((field) => {
        result[field.name] = []
      })
      return result
    }
  },
  methods: {
    onConfirm() {},
    onSelectionConfirm(val) {
      this.modal[this.currentSelectionData.name] = val;
      this.selectionShow = false;
    },
    onDateConfirm(date) {
      this.modal[this.currentDateSelectField] = `${date.getFullYear()}-${
        date.getMonth() + 1
      }-${date.getDate()}`;
      this.showDateSelect = false;
    },
    emitChange() {
      this.$emit("formUpdate", this.modal);
    },
    async fnUploadRequest(option, modal_key) {
      const _self = this;
      console.log(option);
      oss.ossUploadFile(option).then(
        (res) => {
          Toast("上传成功");
          _self.modal[modal_key] = res.fileUrl[0].split("?")[0];
          this.emitChange();
          // _self.fileList[modal_key].push({url: res.fileUrl[0].split("?")[0], isImage: true})
        },
        (err) => {
          console.log("********", err);
          Toast("上传失败");
        }
      );
    },
    onUploadDelete(event, name) {
      this.fileList[name].splice(0, 1);
      this.modal[name] = "";
      this.emitChange();
    },
    onAreaConfirm({selectedOptions}) {
      const _self = this
      _self.modal.locationStr = selectedOptions.map((option) => option.text).join('/')

      this.modal.province_code = selectedOptions[0].value
      this.modal.city_code = selectedOptions[1].value
      this.modal.district_code = selectedOptions[2].value
      this.showLocationSelection = false
    },
  },
};
</script>

<style scoped>
.action_btn {
  position: fixed;
  bottom: 10px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  left: 2.5%;
  text-align: center;
}
.action_bg {
  padding-top: 10px;
  position: fixed;
  bottom: 0px;
  height: 70px;
  width: 100%;
  left: 0px;
  text-align: center;
  background-color: white;
  z-index: 666;
}
.content {
  padding: 16px 16px 160px;
}
::v-deep .van-field__control:read-only {
  cursor: default;
  text-align: right;
}
::v-deep .van-field__control {
    display: block;
    box-sizing: border-box;
    width: 100%;
    min-width: 0;
    margin: 0;
    padding: 0;
    color: #323233;
    line-height: inherit;
    text-align: right;
    background-color: transparent;
    border: 0;
    resize: none;
}
textarea {
  text-align: left !important;
}
.van-field__error-message {
    color: #ee0a24;
    font-size: 12px;
    text-align: right;
}
</style>